<template>
  <div>
    <all-user-tab />
  </div>
</template>
<script>
import AllUserTab from './components/AllUserTab.vue'

export default {
  components: {
    AllUserTab,
  },
}
</script>
<style lang="scss" scoped>

</style>
