<script>
/* eslint-disable global-require */
import {
  BFormInput,
  BInputGroupPrepend,
  BInputGroup,
  BRow,
  BCol,
  BInputGroupAppend,
  BFormGroup,
  BFormSelect,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import {
  PROFILE_STATUS, DATE_RANGE,
} from '@/constants'
import { createNamespacedHelpers } from 'vuex'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import { toastification } from '@core/mixins/toast'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import _get from 'lodash/get'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

const { mapActions, mapGetters } = createNamespacedHelpers('referralProgram')

export default {

  components: {
    flatPickr,
    vSelect,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BRow,
    BCol,
    BInputGroupAppend,
    BFormGroup,
    BFormSelect,
    BtnLoading,
  },
  mixins: [numberFormatMixin, toastification, stepGuideMixin],
  data() {
    return {
      currentPage: 1,
      searchTerm: '',

      // sort
      sortTerm: {
        field: '',
        type: '',
      },

      // per page
      pageLength: 10,
      pages: ['5', '10', '20'],

      // date picker
      rangeDate: {
        startDate: null,
        endDate: null,
      },

      // search Dropdown
      searchSelected: 'localUserId',
      searchOptions: [
        {
          text: 'User ID',
          value: 'localUserId',
        },
        {
          text: 'User email',
          value: 'email',
        },
      ],

      // status filter
      statusSelected: {
        name: this.$t('productLinkPage.statusReviewing'),
        value: PROFILE_STATUS.REVIEWING,
      },

      statusOptions: [
        {
          name: this.$t('payment.balanceTransactionsTable.textAllStatus'),
          value: '',
        },
        {
          name: this.$t('myAdAccountsPage.textApproved'),
          value: PROFILE_STATUS.APPROVED,
        },
        {
          name: this.$t('productLinkPage.statusReviewing'),
          value: PROFILE_STATUS.REVIEWING,
        },
        {
          name: this.$t('partnerView.statusReject'),
          value: PROFILE_STATUS.REJECTED,
        },
        {
          name: this.$t('productLinkPage.statusNotFound'),
          value: PROFILE_STATUS.NOT_FOUND,
        },
      ],

      // week, month, year filter
      dateSelected: null,
      dateOptions: DATE_RANGE,

      columns: [
        {
          label: this.$t('partnerView.labelUserId'),
          field: 'localUserId',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('partnerView.textUserInfo'),
          field: 'fullName',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: 'Product link',
          field: 'productURLs',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-0 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-product-video',
        },
        {
          label: 'Product link status',
          field: 'status',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf th-product-link',
          tdClass: 'p-0 border-left-0 border-right-0 align-middle td-status-video',
        },
        {
          label: 'Product status',
          field: 'products.status',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('partnerView.textRequestAt'),
          field: 'products.requestedAt',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
      ],
    }
  },

  computed: {
    ...mapGetters(['listProductLinks', 'message', 'status', 'loading']),

    listMember() {
      return _get(this.listProductLinks, ['content'], [])
    },

    totalMember() {
      return _get(this.listProductLinks, ['paging', 'total'], [])
    },

    adsAccountImg() {
      return img => img || require('@/assets/images/common/ic-member-manager.svg')
    },

    createdDate() {
      return value => (value?.products?.requestedAt ? new Date(value?.products?.requestedAt).toLocaleString('en-GB') : 'N/A')
    },

    statusVariantRejected() {
      const statusColor = {
        [PROFILE_STATUS.APPROVED]: 'approved',
        [PROFILE_STATUS.REJECTED]: 'rejected',
        [PROFILE_STATUS.REVIEWING]: 'reviewing',
        [PROFILE_STATUS.NOT_FOUND]: 'not-found',
      }
      return status => statusColor[status?.products?.status]
    },

    multiStatusProductLink() {
      const statusTxt = {
        [PROFILE_STATUS.APPROVED]: this.$t('myAdAccountsPage.textApproved'),
        [PROFILE_STATUS.REJECTED]: this.$t('billingPage.statusRejected'),
        [PROFILE_STATUS.REVIEWING]: this.$t('productLinkPage.statusReviewing'),
        [PROFILE_STATUS.NOT_FOUND]: this.$t('productLinkPage.statusNotFound'),
      }
      return status => statusTxt[status?.products?.status]
    },

    statusItemProductLink() {
      const statusColor = {
        [PROFILE_STATUS.APPROVED]: 'approved',
        [PROFILE_STATUS.REJECTED]: 'rejected',
        [PROFILE_STATUS.REVIEWING]: 'reviewing',
        [PROFILE_STATUS.NOT_FOUND]: 'not-found',
      }

      return status => statusColor[status]
    },

    rangeDates() {
      return {
        from: this.rangeDate.startDate,
        to: this.rangeDate.endDate
          ? this.rangeDate.endDate
          : this.rangeDate.startDate,
      }
    },

    listProductLink() {
      return val => {
        const listProductUrl = val?.productURLs || {}

        const arrUrlConverted = Object.keys(listProductUrl).map(value => ({
          productUrl: value,
          ...listProductUrl[value],
        }))

        return arrUrlConverted.reverse() || []
      }
    },

    goToUrl() {
      return tag => {
        if (tag.includes('http://') || tag.includes('https://')) {
          return window.open(tag)
        }
        return window.open(`https://${tag}`)
      }
    },
  },

  created() {
    this.fetchListProductLinks()
  },

  methods: {
    ...mapActions(['getListProductLinks']),

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchListProductLinks()
    },

    onSelect(val) {
      if (val) {
        this.fetchListProductLinks()
      }
    },

    async handleStatusChange() {
      this.currentPage = 1
      await this.fetchListProductLinks()
    },

    async handleSearch(searching) {
      if (this.debounce !== null) {
        await clearTimeout(this.debounce)
      }
      if (searching === '') {
        this.clearSearch()
      } else if (searching.length > 3 && this.searchSelected !== 'email') {
        this.currentPage = 1
        this.debounce = setTimeout(() => {
          this.fetchListProductLinks()
        }, 200)
      }
    },

    clearSearch() {
      this.searchTerm = ''
      this.currentPage = 1
      this.fetchListProductLinks()
    },

    handleSubmitSearch() {
      if (this.searchTerm.length > 3 && this.searchSelected === 'email') {
        this.fetchListProductLinks()
      }
    },

    handleChangeSearchType() {
      this.searchTerm = ''
      this.fetchListProductLinks()
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchListProductLinks()
    },

    async fetchListProductLinks() {
      const params = {
        page: this.currentPage,
        size: this.pageLength,
        ...(this.sortTerm.field && {
          sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${
            this.sortTerm.field
          }`,
        }),
        ...(this.statusSelected?.value && { productStatus: this.statusSelected.value }),
        ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
        ...this.rangeDates,
      }
      await this.getListProductLinks(params)
      if (!this.status) {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.ads-avt-img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}
.close-icon {
  .input-group-text {
    padding-left: 0;
    padding-right: 0;
  }
}
.select-search-custom {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.form-action {
  .currency {
    .vs__dropdown-toggle {
      height: 100%;
      border-radius: 12px;
    }
  }

  .product-tag {
    .b-form-tags {
      padding: 0;
      border: none;
    }

    .card {
      border-radius: 0.625rem;
    }

    .card-body {
      padding: 0.86rem 1rem;
    }
  }
  .input-height {
    .vs__dropdown-toggle {
      border: 1px solid #e0e0e5;
    }
  }
}
</style>
<style lang="scss" scoped>

</style>
