<template>
  <div class="all-user-container bg-white">
    <div class="form-action">
      <!-- filter desktop -->
      <div class="filter-desktop user-management">
        <!-- filter 1 -->
        <b-row class="input-control">
          <b-col
            md="7"
            lg="5"
            class="input-height"
          >
            <div>
              <b-form-group>
                <b-input-group class="input-filter">
                  <b-input-group-prepend>
                    <b-form-select
                      v-model="searchSelected"
                      class="select-search-custom input-height"
                      :options="searchOptions"
                      @input="handleChangeSearchType"
                    />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model.trim="searchTerm"
                    :placeholder="
                      searchSelected === 'email'
                        ? $t('partnerView.placeholderSearchByEmail')
                        : $t('partnerView.placeholderSearchById')
                    "
                    :type="searchSelected === 'localUserId' ? 'number' : 'text'"
                    class="d-inline-block input-height"
                    :class="searchSelected === 'email' ? 'input-search' : ''"
                    @input="handleSearch"
                    @keyup.enter="handleSubmitSearch"
                  />
                  <b-button
                    v-if="searchSelected === 'email'"
                    variant="outline-secondary"
                    class="btn-filter-search input-height"
                    :class="isActiveBtn ? 'active-btn-search' : null"
                    @click.prevent="handleSubmitSearch"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group>
              </b-form-group>
            </div>
          </b-col>

          <b-col
            offset-md="3"
            offset-lg="0"
            offset-xl="1"
            md="5"
            lg="4"
            xl="3"
            class="input-height mt-1 mt-lg-0 px-md-0"
          >
            <div class="d-flex justify-content-center">

              <calendar
                v-model="rangeDate"
                @input="onSelect"
              />
            </div>
          </b-col>

          <b-col
            md="4"
            lg="3"
            class="input-height mt-1 mt-lg-0"
          >
            <div class="status-input input-height">
              <v-select
                v-model="statusSelected"
                class="input-height"
                label="name"
                :options="statusOptions"
                :clearable="false"
                @input="handleStatusChange"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <feather-icon
                      icon="ChevronDownIcon"
                      size="20"
                    />
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- /filter desktop -->
    </div>
    <!-- table -->
    <vue-good-table
      class="vgt-no-border table-member-product-link"
      mode="remote"
      :columns="columns"
      :rows="listMember"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: totalMember > 0,
      }"
      :sort-options="{
        enabled: totalMember > 0,
      }"
      :is-loading="loading"
      @on-sort-change="onSortChange"
    >
      <template
        slot-scope="props"
        slot="table-row"
      >
        <!-- Column: Ad Account -->
        <div
          v-if="props.column.field === 'fullName'"
          class="d-flex align-items-center cursor-pointer"
          @click="goToListProductLink(props.row.localUserId)"
        >
          <b-img
            rounded
            class="ads-avt-img"
            :src="require('@/assets/images/common/ic-member-manager.svg')"
          />
          <div class="d-flex flex-column">
            <p class="text-primary font-weight-600 mb-0">
              {{ props.row.fullName }}
            </p>
            <!--            <span class="text-placeholder">{{ props.row.email }}</span>-->
          </div>
        </div>

        <!-- Column: Status -->
        <div
          v-else-if="props.column.field === 'products.status'"
          class="d-flex align-items-center"
        >
          <div
            v-if="props.row.status"
            class="status-product-links"
            :class="statusVariantRejected(props.row)"
          >
            <span
              v-if="multiStatusProductLink(props.row)"
              class="content-status"
            >{{
              multiStatusProductLink(props.row)
            }}</span>
            <span v-else>
              N/A
            </span>
          </div>
        </div>
        <!-- Column: requestedAt -->
        <span v-else-if="props.column.field === 'products.requestedAt'">
          <div v-if="createdDate(props.row)">
            {{ createdDate(props.row) }}
          </div>
        </span>

        <!-- Column: productURLs -->
        <div v-else-if="props.column.field === 'productURLs'">
          <div v-if="props.row.products">
            <div
              v-for="item, index in listProductLink(props.row.products)"
              :key="index"
              class="productUrl list-product-video"
            >
              <p
                v-b-tooltip.hover.top.v-light="item.productUrl"
                class="url cursor-pointer m-0"
                @click.prevent="goToUrl(item.productUrl)"
              >
                {{ item.productUrl }}
              </p>
            </div>
          </div>
          <div v-else>
            <p
              class="m-0"
              style="padding: 0.5rem;"
            >
              N/A
            </p>
          </div>
        </div>

        <!-- Column: Status product link -->
        <div
          v-else-if="props.column.field === 'status'"
        >
          <div v-if="listProductLink(props.row.products).length > 0">
            <div
              v-for="(item, index) in listProductLink(props.row.products)"
              :key="index"
              class="product-link-status d-flex align-items-center"
            >
              <div
                class="tag-content m-0 content-product-link-status"
              >
                <p
                  class="content-status status-product-links"
                  :class="statusItemProductLink(item.status)"
                >
                  {{ item.status }}
                </p>
              </div>
            </div>
          </div>
          <div
            v-else
            style="padding: 0.5rem;"
          >
            <p class="m-0">
              N/A
            </p>
          </div>
        </div>

        <!-- Column: Action -->
        <div
          v-else-if="props.column.field === 'action'"
          class="d-flex flex-column"
        >
          <div>
            <btn-loading
              class="btn-submit-outline"
              variant-convert="btn-submit-outline"
              pill
              @click="goToListProductLink(props.row.localUserId)"
            >
              View detail
            </btn-loading>
          </div>
        </div>

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        v-if="totalMember > 0"
        slot="pagination-bottom"
      >
        <div class="d-flex flex-wrap footer-tab">
          <b-col
            md="6"
            class="px-0 d-flex align-items-center"
          >
            <span
              class="text-secondary font-14"
            >{{ totalMember }} {{ $t('productLinkPage.textProductLink') }}</span>
          </b-col>
          <!-- page length -->
          <b-col
            v-if="totalMember > pageLength"
            md="6"
            class="px-0"
          >
            <b-pagination
              :total-rows="totalMember"
              :value="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-table"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </div>
      </template>

      <!-- custom loading  -->
      <template slot="loadingContent">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
      </template>

      <div
        slot="emptystate"
        class="text-center"
      >
        <no-data-table-image class="my-5" />
      </div>
    </vue-good-table>
  </div>
</template>
<script>
import {
  BSpinner,
  BImg,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'

import { createNamespacedHelpers } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import NoDataTableImage from '@/components/NoDataTableImage.vue'
import Calendar from '@/components/Calendar.vue'
import envMixin from '@/mixins/envMixin'
import tableMemberProductLink from './tableMemberProductLink.vue'

const { mapGetters } = createNamespacedHelpers('referralProgram')

export default {
  name: 'AllUserTab',
  components: {
    // bootstrap
    BSpinner,
    BImg,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    VueGoodTable,
    NoDataTableImage,
    BButton,

    // component
    Calendar,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [tableMemberProductLink, envMixin],

  data() {
    return {
      productLink: {},
    }
  },

  computed: {
    ...mapGetters(['listProductLinks', 'message', 'status', 'loading']),
    totalProductLinks() {
      return this.listProductUrl?.length
    },

    isActiveBtn() {
      return this.searchTerm.length > 3
    },
  },
  created() {},
  methods: {
    goToListProductLink(params) {
      const routeData = this.$router.resolve(`/member/${params}?tab=product_links`)
      window.open(routeData.href, '_blank')
    },
  },
}
</script>

<style lang="scss">
.table-member-product-link {
  .td-product-video {
    min-width: 150px;
    width: 350px;
  }

  .th-product-link {
    padding-left: 0 !important;
  }

  .td-status-video {
    min-width: 150px;
    width: 200px;
  }
}

.user-management {
  @media(max-width: 400px) {
    .custom-select {
      padding: 0.438rem 2rem 0.438rem 0.5rem;
    }
  }
}
</style>
<style lang="scss" scoped>
.btn-filter-search {
  border-radius: 0 0.625rem 0.625rem 0;
}

.productUrl {
  width: 350px;

  .url {
    text-decoration: underline;
    color: #2667ff;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.list-product-video {
  padding: .5rem;
  height: 74px;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid #ebe9f1;
  }
}
.all-user-container {
  border-radius: var(--border-radius-lg);
  .form-action {
    padding: 21px 24px;
    &.vs__dropdown-toggle {
      height: 40px;
    }
  }
  .form-group {
    margin-bottom: 0;
  }
}
.input-height {
  height: 40px;
}
.footer-tab {
  padding: 27px 24px;
}

.status-product-links {
  padding: 5px 12px;
  border-radius: 100px;
  width: max-content;

  .content-status {
    margin: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }

  &.approved {
    color: #1db9c3;
    background-color: #1db9c31a;
  }

  &.rejected {
    color: #e11c4e;
    background-color: #e11c4e1a;
  }

  &.reviewing {
    color: #ff9903;
    background-color: #fbb03b1a;
  }
}

.product-link-status {
  height: 74px;
  padding: 0.5rem;

  &:not(:last-child) {
    border-bottom: 1px solid #ebe9f1;
  }

  .content-product-link-status {
    padding: 0.366rem 0;
  }
}

.status-product-links {
  padding: 5px 12px;
  border-radius: 100px;
  width: max-content;

  &.content-status {
    margin: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }

  &.approved {
    color: #1db9c3;
    background-color: #1db9c31a;
  }

  &.rejected {
    color: #e11c4e;
    background-color: #e11c4e1a;
  }

  &.reviewing {
    color: #ff9903;
    background-color: #fbb03b1a;
  }
}

.select-search-custom {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
